<template>
  <div class="staging">
    <el-dialog
      title="待还信息"
      :visible.sync="isShow"
      width="70%"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
       <div class="account-table">
        <el-table border :data="list" style="width: 100%" :row-style="{ height: 0 }">
        <el-table-column prop="staging" label="期数" align="center"></el-table-column>
        <el-table-column prop="orderSn" label="订单号" align="center" min-width="120"></el-table-column>
        <el-table-column prop="amount" label="应还金额" align="center">
          <template slot-scope="scope">{{scope.row.amount / 100 ?scope.row.amount / 100 :''}}</template>
        </el-table-column>
        <el-table-column prop="interestAmount" label="应还利息" align="center">
          <template slot-scope="scope">{{scope.row.interestAmount / 100 ?scope.row.interestAmount / 100 :''}}</template>
        </el-table-column>
        <el-table-column prop="costAmount" label="总金额" align="center">
          <template slot-scope="scope">{{scope.row.costAmount / 100 ?scope.row.costAmount / 100 :''}}</template>
        </el-table-column>
        <el-table-column prop="finished" label="状态" align="center">
            <template slot-scope="scope">
                <el-tag
                    size="mini"
                    :effect ="scope.row.finished ? 'plain' : 'dark'"
                >{{ scope.row.finished ? '已还' : '未还' }}</el-tag>
            </template>
        </el-table-column>

        <el-table-column prop="timeLimit" label="应还时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.timeLimit.substr(0,10) }}
          </template>
        </el-table-column>
        <el-table-column prop="updateAt" label="还款时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" min-width="200">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.finished"
            type="warning"
            size="mini"
            icon="el-icon-edit"
            @click="trueStag(scope.row )"
          >确认还款</el-button>
          <el-button
            v-if="!scope.row.finished"
            type="success"
            size="mini"
            @click="showRenewEdit(scope.row )"
          >续期</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <renew
      :is-show="showRenew"
      @closeEdit="showRenew = false"
      @submitEdit="submitRenew"
    >
    </renew>
  </div>
</template>

<script>
import service from '../api'

import renew from "./renewEdit"
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  components:{ renew },
  data() {
    return {
      list: [],
      showRenew: false,
      renewId:''
    };
  },
  computed: {
    // title() {
    //   let { username = '', mobile = '' } = this.editData;
    //   return username ? '编辑用户 -' + username : '编辑用户 - ' + mobile;
    // }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
        this.$emit('submit');
    },
    trueStag(row){
        this.$confirm('是否确认还款?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           return service.trueStag({id: row.id}).then(res => {
                if (res.code == '00000') {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                   this.getTable()
                }
            });

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
    },
    getTable(){
       let {id} = this.editData
        return service.stagTable({id: id}).then(res => {
            this.list = res;
        });
    },
    openDialog() {
        this.$nextTick(() => {
           this.getTable()
      });
    },
    //续期天数
    showRenewEdit(row){
      this.showRenew = true;
      this.renewId = row.id;
    },
    //续期天数提交
    submitRenew(value){
      let {id} = this.editData;
      return service.renew({stagingId: this.renewId, days: value || 0}).then(res => {
        if (res.code == "00000") {
          this.showRenew = false;
          this.$notify({ type: "success", message: "操作成功！" });
          this.getTable()
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.staging {
    .account-table{
        .el-button {
            padding: 4px;
            font-size: 11px;
        }
    }

}
</style>
